import mobile4 from "../assets/img/dashboard.svg";
import mobile3 from "../assets/img/login.svg";
import iphone from "../assets/img/mobile2.png";
import mobile5 from "../assets/img/property-slider1.svg";
import mobile1 from "../assets/img/splash-main.svg";
import mobile2 from "../assets/img/zipcode1.svg";
import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { EffectCoverflow, Navigation } from "swiper/modules";

export default function MobileSlider() {
  const swiperRef = useRef(null);

  console.log(swiperRef);
  return (
    <>
      <div
        id="screenshots"
        screenshots
        className="flex flex-col relative items-center justify-center mx-auto bg-white  md:py-16 py-10  "
      >
        <p className="page-title">Our App Screenshots</p>
        <p className=" page-desc py-3 md:py-5 px-5 w-11/12 sm:w-[80%] md:w-[65%] lg:w-[45%]">
          Showcase properties effortlessly, connect with potential buyers, and
          manage open houses with ease. Download Scout Heim Agent now!
        </p>
        <img
          src={iphone}
          alt="Mobile"
          className="absolute -translate-y-1/2 -translate-x-1/2  top-[58%] z-40 left-1/2  mobile-image"
        />

        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,

            depth: 100,
            modifier: 2,
            slideShadows: true,
          }}
          ref={swiperRef}
          navigation={{
            // Add navigation prop here
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next",
          }}
          loop={true}
          modules={[EffectCoverflow, Navigation]} // Include necessary modules
          className="myMobileSwiper mt-20"
        >
          <SwiperSlide>
            <img src={mobile1} className="h-[586px]  object-contain" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={mobile2} className="h-[586px]  object-contain" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={mobile3} className="h-[586px]  object-contain" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={mobile4} className="h-[586px]  object-contain" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={mobile5} className="h-[586px]  object-contain" />
          </SwiperSlide>
        </Swiper>

        <div className="swiper-button-prev">
          <RiArrowLeftSLine />
        </div>
        <div className="swiper-button-next">
          <RiArrowRightSLine />
        </div>
      </div>
    </>
  );
}
