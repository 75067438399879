import aboutmobile from "../assets/img/about-mobile.svg";
import React from "react";

const About = () => {
  return (
    <div className="about-us py-10 " id="about-us">
      <div className="max-w-6xl  mx-auto flex flex-wrap   justify-center place-items-center gap-6">
        {/* <div className="max-w-6xl  mx-auto grid grid-cols-1 md:grid-cols-2  justify-center place-items-center gap-6"> */}
        <div className="lg:w-[56%] md:w-[60%] flex lg:items-end items-center  flex-col w-11/12">
          <div className="">
            <p className="lg:text-right text-left lg:text-[60px] text-4xl text-white font-semibold">
              About Us
            </p>
          </div>
          <div className="mb-6 lg:min-w-[196px] min-w-[90px] h-5 border-b border-white"></div>
          <p className="lg:text-right text-left lg:text-[18px] md:text-[14px] sm:text-[14px] text-[14px] font-medium text-[white]">
            Scout Heim Agent is dedicated to transforming the real estate
            industry by offering cutting-edge tools for open house hosting. Our
            app is meticulously designed to simplify property showcasing, foster
            seamless connections with potential buyers, and streamline the
            management of open houses. With an intuitive interface and powerful
            features, we empower real estate professionals to thrive in a
            competitive market.
          </p>
          <br />
          <br />

          <p className="lg:text-right text-left lg:text-[18px] md:text-[14px] sm:text-[14px] text-[14px] font-medium text-[white]">
            Our mission is to enhance your real estate endeavors by providing a
            platform that redefines client and property engagement. Experience
            the future of real estate with Scout Heim Agent and elevate your
            open house strategy to new heights.
          </p>
        </div>

        <div className="lg:w-[40%] lg:block flex items-center justify-center md:w-[70%] w-11/12">
          <img src={aboutmobile} alt="aboutmobile" />
        </div>
      </div>
    </div>
  );
};

export default About;
