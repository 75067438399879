import React from "react";
import instagram from "../assets/img/insta.svg";
import facebook from "../assets/img/facebook.svg";
import linkedin from "../assets/img/linked.svg";
import google from "../assets/img/google.svg";
import googleplay from "../assets/img/gplay.svg";
import appstore from "../assets/img/app-store.svg";

const Footer = () => {
  return (
    <>
      <div className="bg-[#FF9A3E] footer">
        <div className="lg:w-[95%] w-[97%] mx-auto text-white lg:p-9 p-5">
        {/*   <div className="flex gap-3 flex-wrap justify-center pb-8">
            <div className="md:basis-[48%] basis-full">
              <h1 className="md:text-[30px] sm:text-[22px] text-[22px] font-medium pb-4">
                Subscribe For New Features
              </h1>
              <p className="md:text-[16px] sm:text-[13px] text-[13px]">
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour,
              </p>
            </div>

            <div className="md:basis-[48%] basis-full">
              <div class="group sm:w-full lg:mt-0 mt-3 w-full md:w-80 lg:w-[70%] ml-auto">
                <div class="relative flex items-center">
                  <input
                    id="8"
                    type="text"
                    placeholder="Enter Your Email"
                    class="peer relative outline-none md:py-[13px] rounded-r-[30px] px-[25px] w-full bg-[#FF9A3E] md:text-[15px] border rounded-l-[30px] placeholder-white sm:pt-[8px] sm:pb-[8px] pt-[8px] pb-[8px]"
                  />

                  <button
                    class="absolute right-0  bg-white text-[#FF9A3E]  md:text-[14px] md:py-[15px] md:px-[25px] 
                sm:text-[11px] sm:py-[12px] sm:px-[20px]  text-[11px] py-[12px] px-[20px]
                font-semibold  rounded-[30px]"
                  >
                    SUBSCRIBE NOW
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr className="w-full mx-auto" />
 */}
          <div className="w-[95%] mx-auto">
            <div className="flex flex-col relative">
              <div className="pt-9">
                <ul className="flex justify-center pb-5 md:text-[18px]  sm:text-[14px] text-[14px]">
                  <li>
                    <a
                      href="#"
                      class="px-3 whitespace-nowrap"
                      aria-current="page"
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      href="#how-it-works"
                      class="px-3 whitespace-nowrap"
                      aria-current="page"
                    >
                      How It’s Work
                    </a>
                  </li>
                  <li>
                    <a
                      href="#about-us"
                      class="px-3 whitespace-nowrap"
                      aria-current="page"
                    >
                      About Us
                    </a>
                  </li>
                  <li>
                    <a
                      href="#screenshots"
                      class="px-3 whitespace-nowrap"
                      aria-current="page"
                    >
                      Screenshots
                    </a>
                  </li>
                </ul>
                <div className="text-center md:text-[18px]  sm:text-[17px] text-[15px] mb-6">
                  <p className="text-center md:text-[18px]  sm:text-[17px] text-[15px] flex flex-col items-center  justify-center">
                    © Copyright 2024 Scout Heim, All Rights Reserved.
                  </p>

                  <p className="md:text-[18px]  sm:text-[17px] text-[15px]">
                    Crafted with
                    <span className="text-red-500 px-2 pt-2 text-lg">
                      &#10084;
                    </span>
                    By{" "}
                    <a
                      href="https://vytechenterprise.com/"
                      className="hover:text-blue-500 transition-all"
                      target="_blank"
                    >
                      {" "}
                      Vytech Enterprise
                    </a>
                    .
                  </p>
                  <span></span>
                </div>
              </div>

              {/*    <div className="justify-center flex pt-6">
                <div className="p-3 mr-4 border rounded-full cursor-pointer">
                  <img src={instagram} alt="" />
                </div>
                <div className="p-3 px-4 mr-4 border rounded-full cursor-pointer">
                  <img src={facebook} alt="" />
                </div>
                <div className="p-3 mr-4 border rounded-full cursor-pointer">
                  <img src={linkedin} alt="" />
                </div>
                <div className="p-3 mr-4 border rounded-full cursor-pointer">
                  <img src={google} alt="" />
                </div>
              </div> */}
              <div className="flex flex-col gap-y-4 lg:absolute right-0 top-1/2 lg:-translate-y-1/2  justify-center pt-2 items-center">
                <a
                  href="https://play.google.com/store/apps/details?id=com.vytech.scoutheim.agent&pcampaignid=web_share"
                  target="_blank"
                  className="mr-4"
                >
                  <img src={googleplay} alt="" />
                </a>
                <a
                  href="https://apps.apple.com/in/app/scout-heim-agent/id6471482643"
                  target="_blank"
                  className="mr-4"
                >
                  <img src={appstore} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
