import React, { useState } from "react";
import cross from "../assets/img/cross.svg";
import minus from "../assets/img/minus.svg";

const FAQItem = ({ question, answer, isVisible, onToggle }) => {
  return (
    <div className="w-full">
      {!isVisible ? (
        <div className="flex min-h-[70px] w-full flex-row border-2 border-l-[6px] border-l-[#FFCD9E] p-1 pl-3 items-center justify-between rounded-md">
          <p className="sm:text-[16px] md:text-xl font-medium">{question}</p>
          <div
            className="rounded-md justify-center flex items-center cursor-pointer min-w-[50px] min-h-[50px]"
            onClick={onToggle}
          >
            <img src={cross} alt="cross" />
          </div>
        </div>
      ) : (
        <div className="flex min-h-[70px] flex-col w-full">
          <div className="flex flex-row h-[70px] items-center rounded-tl-md rounded-tr-md justify-between p-1 pl-3 border-b-0 bg-gradient-to-t from-[#ffca95] via-ffca95 to-[#fff6ed] border-2">
            <p className="sm:text-[16px] md:text-xl font-medium">{question}</p>
            <div
              className="bg-[#fab570] rounded-md justify-center flex items-center cursor-pointer min-w-[50px] min-h-[50px]"
              onClick={onToggle}
            >
              <img src={minus} alt="minus" />
            </div>
          </div>
          <div className="flex flex-col p-3 rounded-br-md rounded-bl-md flex-wrap border-t-0 bg-white border-2">
            <p className="text-[13px] md:text-base">{answer}</p>
          </div>
        </div>
      )}
    </div>
  );
};
const Faq = () => {
  const faqData = [
    {
      question:
        "How do I register visitors for an open house using Scout Heim Agent?",
      answer:
        "To register visitors for an open house, simply use the app's visitor registration feature. You can quickly add visitors, capture their details, and keep track of who attends the event.",
    },
    {
      question:
        "Can I access analytics and insights about my property listings on Scout Heim Agent?",
      answer:
        "Yes, Scout Heim Agent provides detailed analytics and insights about your property listings. You can track metrics such as property views, inquiries, and more to understand how your listings are performing.",
    },
    {
      question:
        "Does Scout Heim Agent offer integration with other real estate tools and platforms?",
      answer:
        "Yes, Scout Heim Agent seamlessly integrates with other real estate tools and platforms. This allows you to streamline your workflow and access all your important tools in one place.",
    },
    {
      question:
        "Is there a support team available to assist me with any issues or questions I have about Scout Heim Agent?",
      answer:
        "Yes, Scout Heim Agent offers dedicated support to assist you with any issues or questions you may have. You can reach out to the support team for help with using the app or troubleshooting any problems.",
    },
    {
      question: "How do I showcase my properties on Scout Heim Agent?",
      answer:
        "To showcase your properties, simply create a new listing within the app. You can add detailed descriptions, high-quality images, and even virtual tours to make your properties stand out.",
    },
    {
      question: "Can I schedule property viewings through Scout Heim Agent?",
      answer:
        "Yes, you can schedule property viewings directly through the app. Simply communicate with potential buyers, arrange a suitable time, and add the viewing to your schedule.",
    },
    {
      question: "Is Scout Heim Agent available offline?",
      answer:
        "Yes, Scout Heim Agent offers offline access to key features and information. This allows you to manage your properties and client interactions even when you're not connected to the internet.",
    },
    {
      question: "How secure is my data on Scout Heim Agent?",
      answer:
        "Scout Heim Agent prioritizes the security of your data. The app is equipped with built-in security features to ensure that your client data and property information remain safe and secure.",
    },
    {
      question: "Can I customize the app to match my branding?",
      answer:
        "Yes, Scout Heim Agent allows you to customize your property listings and client interactions to match your branding and style. This helps you create a cohesive brand image and stand out in a competitive market.",
    },
  ];
  const [visibilities, setVisibilities] = useState(
    Array(faqData && faqData.length).fill(false)
  );

  const toggleVisibility = (index) => {
    setVisibilities((prevVisibilities) => {
      const updatedVisibilities = [...prevVisibilities];
      updatedVisibilities[index] = !updatedVisibilities[index];
      return updatedVisibilities;
    });
  };

  return (
    <div
      id="faqs"
      className="flex flex-col items-center justify-center mx-auto bg-white  md:py-12 py-8"
    >
      <p className="page-title">FAQ</p>
      <p className=" page-desc py-3 md:py-5 lg:px-5 px-3 w-11/12  md:w-[65%] lg:w-[45%]">
        Have questions about Scout Heim Agent? Check out our FAQs below for
        answers to commonly asked questions. If you can't find what you're
        looking for, feel free to contact our support team for assistance.
      </p>
      <div className="max-w-4xl pt-3 w-full p-2 justify-between flex flex-col gap-y-4 px-3 bg-white flex-wrap mx-auto my-10">
        {faqData.map((faq, index) => (
          <FAQItem
            key={index}
            question={faq.question}
            answer={faq.answer}
            isVisible={visibilities[index]}
            onToggle={() => toggleVisibility(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Faq;
