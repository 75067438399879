import itwork from "../assets/img/itwork.svg";
import share from "../assets/img/share&collaborate.svg";
import add from "../assets/img/add.svg";
import engage from "../assets/img/engage.svg";
import host from "../assets/img/host.svg";
import React from "react";

const HowItWorks = () => {
  return (
    <>
      <div
        className="w-full flex-center flex-col mx-auto  pt-20"
        id="how-it-works"
      >
        <p className="page-title">How It Works</p>
        <p className="text-center page-desc py-3 md:py-5 px-5 w-11/12 sm:w-[80%] md:w-[65%] lg:w-[45%]">
          ScoutHeim simplifies property management for all users with its
          intuitive platform, enhancing communication and efficiency throughout
          the rental process.
        </p>
      </div>
      <div
        id="how-it-works"
        class="lg:w-[85%] w-11/12 justify-center mx-auto pb-10"
      >
        <div class="flex gap-4 flex-wrap justify-center">
          <div class="py-2 xl:basis-[30%] md:basis-[48%] min-h-[300px] basis-full">
            <div className="flex flex-col items-center border justify-center text-center h-full border-[#44352733] p-5 rounded-[20px]">
              <img src={share} alt="" />
              <h4 className="text-[25px] pt-8 font-semibold">
                Share & Collabrate
              </h4>
              <p className="text-[16px] pt-1">
                Share Property information to your potential clients in one
                single click, Compare the properties shared by your clients,
                collaborate with your clients for exchanging key attributes of
                their favourite property.
              </p>
            </div>
          </div>
          <div class="py-2 be-2 xl:basis-[30%] md:basis-[48%] min-h-[300px] basis-full ">
            <div className="flex flex-col items-center border text-center border-[#44352733] p-5 rounded-[20px] h-full">
              <img src={host} alt="" />
              <h4 className="text-[25px] pt-8 font-semibold">
                Hosting Open Houses
              </h4>
              <p className="text-[16px] pt-1">
                Use the app to schedule and manage open houses. Scout Heim Agent
                provides tools for effortlessly setting up events, inviting
                potential buyers, and organizing property showings.
              </p>
            </div>
          </div>
          <div class="py-2 xl:basis-[30%] md:basis-[48%] min-h-[300px] basis-full">
            <div className=" flex flex-col text-center justify-center items-center h-full border border-[#44352733] p-5 rounded-[20px]">
              <img src={engage} alt="" />
              <h4 className="text-[25px] pt-8 font-semibold">
                Engage and Connect
              </h4>
              <p className="text-[16px] pt-1">
                Interact with potential buyers through the app’s messaging and
                feedback features. Seamlessly follow up with leads and manage
                client relationships, ensuring a streamlined real estate
                experience.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowItWorks;
