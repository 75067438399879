import logo from "./logo.svg";
import "./App.css";
import "./assets/scss/index.scss";
import Home from "./component/Home";
import { Toaster, toast } from "sonner";

function App() {
  return (
    <>
      <Toaster
        position="top-center"
        expand={true}
        visibleToasts={1}
        toastOptions={{
          unstyled: false,
          duration: 4000,
        }}
        richColors={true}
        closeButton={true}
      />
      <Home />
    </>
  );
}

export default App;
